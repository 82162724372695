<template>
  <div class="flex flex-col items-center py-4">
    <business-form @onMyBusinessFormSave="onSave" />
  </div>
</template>
<script>
import AdminService from '@/services/AdminService';

export default {
  name: 'MyBusiness',
  components: {
    BusinessForm: () => import('@/components/forms/BusinessForm'),
  },
  methods: {
    async onSave(model) {
      const formData = new FormData();
      formData.append('id', this.$store.state.business.id);
      if (model.logo) formData.append('logo', model.logo);
      if (model.name) formData.append('name', model.name);
      if (model.phone) formData.append('phone', model.phone);
      if (model.address) formData.append('address', model.address);
      if (model.country) formData.append('country', model.country);
      if (model.currency) formData.append('currency', model.currency);
      if (model.timezone) formData.append('timezone', model.timezone);
      if (model.phoneCountryCodeId) formData.append('phoneCountryCodeId', model.phoneCountryCodeId);
      if (model.dateFormat) formData.append('dateFormat', model.dateFormat);
      try {
        await AdminService.MyBusiness.update(formData);
        this.$toastr.s('Se actualizo con exito');
      } catch (e) {
        this.$toastr.e('Error desconocido. Por favor, vuelva a intentarlo.');
      }
    },
  },
}
</script>
